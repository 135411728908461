<template>
  <div
    class="d-flex flex-column flex-wrap flex-md-row align-items-center ignore-box-sizing w-100 custom-gap"
  >
    <slot></slot>
    <b-button variant="success" style="margin-top: 4px" @click="submitFilters">
      Submit
    </b-button>
  </div>
</template>

<script>
export default {
  emits: ["submitFilters"],
  methods: {
    submitFilters() {
      this.$emit("submitFilters");
    },
  },
  components: {
    BButton,
  },
};
import { BButton } from "bootstrap-vue";
</script>
<style scoped>
.ignore-box-sizing * {
  box-sizing: border-box;
}
.custom-gap {
  gap: 0 10px;
}
</style>
<style>
.w-24 {
  width: 23% !important;
}

@media only screen and (max-width: 468px) {
  .filters-width {
    width: 100% !important;
  }
}
</style>
