<template>
  <b-row>
    <b-col cols="12">
      <b-table-simple responsive bordered striped class="w-100">
        <b-thead>
          <b-tr>
            <b-th v-for="col in tableColumns">
              {{ col.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(row, rowIndex) in tableItems">
            <td v-for="col in tableColumns">
              <template v-if="!$scopedSlots[col.key]">
                {{
                  col.formatter
                    ? col.formatter(row[col.key], col.label, row)
                    : row[col.key]
                }}
              </template>
              <template v-else>
                <slot :name="col.key" :index="rowIndex" :item="row"></slot>
              </template>
            </td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <!-- <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        bordered
        striped
        empty-text="There are no items available"
      >
        <template #cell(actions)="data">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <b-button
              v-if="editRenderCondition(data.item)"
              variant="transparent"
              size="sm"
              @click="editCallbackFunction(data.item)"
            >
              <feather-icon icon="EditIcon" class="text-primary" size="20" />
            </b-button>
            <b-button
              v-if="deleteRenderCondition(data.item)"
              variant="transparent"
              size="sm"
              @click="deleteCallbackFunction(data.item)"
            >
              <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
            </b-button>
            <b-button
              v-if="customRenderCondition(data.item)"
              variant="transparent"
              size="sm"
              @click="customCallbackFunction(data.item)"
            >
              <feather-icon
                :icon="customIcon"
                :class="customIconClass"
                :size="customIconSize"
              />
            </b-button>
          </div>
        </template>
      </b-table> -->
    </b-col>
    <b-col cols="12">
      <custom-pagination
        v-if="totalCount"
        @changePage="changePage"
        :pageNumber="pageNumber"
        :count="count"
        :totalCount="totalCount"
      ></custom-pagination>
    </b-col>
  </b-row>
</template>

<script>
export default {
  emits: ["changePage"],
  props: {
    pageNumber: {
      type: [String, Number],
      required: false,
    },
    count: {
      type: [String, Number],
      required: false,
    },
    totalCount: {
      type: [String, Number],
      required: false,
    },
    editCallbackFunction: {
      type: Function,
      required: false,
      default: () => false,
    },

    editRenderCondition: {
      type: Function,
      required: false,
      default: () => false,
    },
    customRenderCondition: {
      type: Function,
      required: false,
      default: () => false,
    },
    customCallbackFunction: {
      type: Function,
      required: false,
      default: () => false,
    },
    deleteCallbackFunction: {
      type: Function,
      required: false,
      default: () => false,
    },
    deleteRenderCondition: {
      type: Function,
      required: false,
      default: () => false,
    },
    tableItems: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    customIconSize: {
      type: String,
      required: false,
      default: "20",
    },
    customIconClass: {
      type: String,
      required: false,
      default: "text-primary",
    },
    customIcon: {
      type: String,
      required: false,
      default: "ExternalLinkIcon",
    },
  },
  methods: {
    changePage(pageNumber) {
      this.$emit("changePage", pageNumber);
    },
  },
  components: {
    BTableSimple,
    BTr,
    BTh,
    BThead,
    BTable,
    BTbody,
    BTfoot,
    BButton,
    BLink,
    BRow,
    BCol,
    CustomPagination,
  },
};
import {
  BTable,
  BButton,
  BLink,
  BRow,
  BCol,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import CustomPagination from "@/views/components/Utilities/CustomPagination.vue";
</script>
