<template>
  <b-overlay class="w-100" :show="isLoading">
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col cols="12">
              <div
                class="w-100 d-flex align-items-center justify-content-between mb-3"
              >
                <h1><slot name="title"></slot></h1>
                <slot name="titleBtn"></slot>
              </div>
            </b-col>
            <b-col cols="12" v-if="isExtended">
              <div class="w-100 d-flex align-items-center mb-3">
                <slot name="extend"></slot>
              </div>
            </b-col>
            <b-col cols="12" class="mb-1" v-if="hasFilters">
              <filters-wrapper @submitFilters="submitFilters">
                <slot name="filters"></slot>
              </filters-wrapper>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <slot></slot>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <slot name="footer"></slot>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
export default {
  emits: ["submitFilters"],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExtended: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    submitFilters() {
      this.$emit("submitFilters");
    },
  },
  components: { BCard, BRow, BCol, BOverlay, FiltersWrapper },
};
import FiltersWrapper from "@/views/components/Utilities/FiltersWrapper.vue";
import { BCard, BRow, BCol, BOverlay } from "bootstrap-vue";
</script>
