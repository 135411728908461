<template>
  <div class="w-100 d-flex align-items-center justify-content-center">
    <b-pagination
      v-model="localPageNumber"
      @change="emitPageChange"
      :total-rows="totalCount"
      :per-page="count"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  emits: ["changePage"],
  watch: {
    pageNumber: {
      immediate: true,
      handler(val) {
        if (val && val != this.localPageNumber) {
          this.localPageNumber = val;
        }
      },
    },
  },
  props: {
    pageNumber: {
      type: [String, Number],
      required: true,
    },
    count: {
      type: [String, Number],
      required: true,
    },
    totalCount: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      localPageNumber: 1,
    };
  },
  methods: {
    emitPageChange(pageNumber) {
      this.$emit("changePage", pageNumber);
    },
  },
  components: {
    BPagination,
  },
};
import { BPagination } from "bootstrap-vue";
</script>
